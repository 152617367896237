import Vue from 'vue'
// import Vuex from 'vuex'
// import axios from 'axios'
// import router from '@/router'

var accounting = require('accounting')
export default {
  namespaced: true,
  state: {
    carrito: [],
    carritovisible: false,
    tc: '',
    ultnumart: '',
    paquetes: []
  },

  mutations: {
    TC (state, value) {
      state.tc = value
    },

    ADDCARRITO (state, value) {
      // console.log('ADDCARRITO', value)
      // verificamos que el arreglo tenga más de un objeto
      if (state.carrito.length >= 1) {
        // hacemos un recorrido por el areglo de objetos
        for (var i = state.carrito.length - 1; i >= 0; i--) {
          // comparamos si la clave del nuevo articulo ya existe en el arreglo de objetos
          // console.log(value.clave)
          if (state.carrito[i].clave === value.clave) {
            // si existe, incrementamos la cantidad en el nuevto
            // value.cantidad = state.carrito[i].cantidad
            value.preciopub = accounting.formatNumber(value.preciopub, 2)
            value.precio = accounting.formatNumber(value.precio, 2)

            // eliminamos el objetos que tiene esa clavee
            state.carrito.splice(i, 1)
          }
        }
      }
      // añadimos el nuevo articulo
      // console.log("state", state.carrito)
      state.carrito.push(value)
      // console.log("state", state.carrito)
    },

    LIMPIAR (state, value) {
      state.carrito = []
      state.paquetes = []
      state.ultnumart = ''
    },

    ACTUALIZARCARRITO (state, value) {
      // console.log("actualizar carrrito",state.carrito)
      for (var i = state.carrito.length - 1; i >= 0; i--) {
	 			if (state.carrito[i].divisa == 'D' && value.divisa == 'P') {
	 				// la divisa cambia
          state.carrito[i].divisa = 'P'
          // se actualizan los precios y los importes
          state.carrito[i].precio = accounting.unformat(state.carrito[i].precio) * value.tc
          state.carrito[i].precio = accounting.formatNumber(state.carrito[i].precio, 2)

          state.carrito[i].preciopub = accounting.unformat(state.carrito[i].preciopub) * value.tc
          state.carrito[i].preciopub = accounting.formatNumber(state.carrito[i].preciopub, 2)

          state.carrito[i].importe = accounting.unformat(state.carrito[i].importe) * value.tc
          state.carrito[i].importe = accounting.formatNumber(state.carrito[i].importe, 2)
        } else if (state.carrito[i].divisa == 'P' && value.divisa == 'D') {
        	// La divisa se pasa a dolares
          state.carrito[i].divisa = 'D'
          // se actualizan los preciopubs y los importes
          state.carrito[i].precio = accounting.unformat(state.carrito[i].precio) / value.tc
          state.carrito[i].precio = accounting.formatNumber(state.carrito[i].precio, 2)

          state.carrito[i].preciopub = accounting.unformat(state.carrito[i].preciopub) / value.tc
          state.carrito[i].preciopub = accounting.formatNumber(state.carrito[i].preciopub, 2)

          state.carrito[i].importe = accounting.unformat(state.carrito[i].importe) / value.tc
          state.carrito[i].importe = accounting.formatNumber(state.carrito[i].importe, 2)
        } else {
        	state.carrito[i].precio = accounting.unformat(state.carrito[i].precio)
          state.carrito[i].precio = accounting.formatNumber(state.carrito[i].precio, 2)

          state.carrito[i].preciopub = accounting.unformat(state.carrito[i].preciopub)
          state.carrito[i].preciopub = accounting.formatNumber(state.carrito[i].preciopub, 2)

          state.carrito[i].importe = accounting.unformat(state.carrito[i].importe)
          state.carrito[i].importe = accounting.formatNumber(state.carrito[i].importe, 2)
        }
      }
      // // Se recorre todo el carrito
      // for (var i = state.carrito.length - 1; i >= 0; i--) {
      // 	//si la divisa del objeto esta en dolares y la divisa que se requiere es en pesos
      // }
    },

    SETCOSTOENVIO (state, preciobase) {
      if (state.carrito.length >= 1) {
        state.carrito.forEach(element => {
          if (element.clave === 'ENVIO') {
            element.precio = preciobase
          }
        })
      }
    },

    SET_PAQUETE (state, paquete) {
      // console.log('SET_PAQUETES', paquete)
      state.paquetes= paquete
    },


    SET_ULT_NUMART (state, numart) {
      // console.log('SET_ULT_NUMART', numart)
      state.ultnumart = numart
    },

    SET_PAQUETES (state, paquete) {
      // console.log('SET_PAQUETES', paquete)
      state.paquetes.push(paquete)
    },



    DEL_PAQUETE (state, tipoempdel) {
      // BORRAR EL TIPOE EMP
      // console.log('getPaquetes', state.paquetes, tipoempdel)

      for (var i = state.paquetes.length - 1; i >= 0; i--) {
        if (state.paquetes[i].tipoemp == tipoempdel) {
          console.log('FOR paquetes.tipoemp', state.paquetes[i].tipoemp, 'articulo.tipoemp ', tipoempdel)
          console.log('DEBE BORRA AL  ', i, state.paquetes[i].tipoemp)
          state.paquetes.splice(i, 1)
        }
      }
      // let nCostoEnv = 0
      // state.paquetes.forEach(element => {
      //   nCostoEnv = element.costoenv + nCostoEnv
      // })
      //Mandar action para actualizar el costo del envio, si quito uno.
      // store.dispatch('setCostoEnvio', (nCostoEnv))
      // console.log('paquetes', state.paquetes)
    }







  },

  actions: {
    carritoadd ({ commit }, articulo) {
      // console.log("carrito add",articulo)
      return new Promise((resolve, reject) => {
        var accounting = require('accounting')
        commit('ADDCARRITO', articulo)
        commit ('SET_ULT_NUMART', articulo.numart)
        resolve(true)
      })
    },

    setCostoEnvio ({ commit }, preciobase) {
      commit('SETCOSTOENVIO', preciobase)
    },

    
    setPaquete ({ commit }, paquete) {
      commit('SET_PAQUETE', paquete)
    },

    setultnumart ({ commit }, numart) {
      commit('SET_ULT_NUMART', numart)
    },



    setPaquetes ({ commit }, paquetes) {
      commit('SET_PAQUETES', paquetes)
    },


    delPaquete ({ commit }, tipoempdel) {
      console.log('tipoemp', tipoempdel)
      commit('DEL_PAQUETE', tipoempdel)
    },

    actualizaCarrito ({ commit }, divisa) {
      return new Promise((resolve, reject) => {
        // sirve para cambiar la divisa del carrito
        commit('ACTUALIZARCARRITO', divisa)
        resolve(true)
      })
    },

    limpiarCarrito ({ commit }, value) {
      return new Promise((resolve, reject) => {
        // Se vacia el carrito
        commit('LIMPIAR', value)
        resolve(true)
      })
    },

    traerTC ({ commit }, tc) {
      return new Promise((resolve, reject) => {
        Vue.http.get('api/v1/tctienda').then(response => {
          commit('TC', response.body.Tctienda)
          resolve(true)
        }).catch(error => {
          console.log(error)
        })
      })
    }

  },

  getters: {
    getCarrito (state) {
      return state.carrito
    },

    getTC (state) {
      return state.tc
    },

    getCarritoVisible (state) {
      return state.carritovisible
    },

    getUltNumart (state) {
      return state.ultnumart
    },

    getPaquetes (state) {
      return state.paquetes
    }

  }
}
