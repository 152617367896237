import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
var accounting = require('accounting')

export default {
  namespaced: true,
  state: {
    articulos: '',
    articulosxlinea: '',
    promos: '',
    art: ''
  },

  mutations: {
    ARTICULOS (state, value) {
      state.articulos = value
    },

    ARTICULOSXLINEA (state, value) {
      state.articulosxlinea = value
    },

    PROMOS (state, promos) {
      state.promos = promos
    },

    VERART (state, art) {
      state.art = art
    },

    // SetArticulosxLineaPaginados
    ARTICULOSXLINEA_PAGINADOS (state, value) {
      state.articulosxlinea = value
    }

  },

  actions: {

    traerArticulosxLinea ({ commit }, value) {
      var url = Vue.http.options.root.replace('tienda3', 'fotossait/')

      // console.log ("articulos value precioutil", value)
      // console.log("traerArriculosxlinea", value)

      let urltipo = ''

      if (value.tipoclase == 'linea') {
        // let urltipoclaseLinea = 'api/v1/arts.bylinea/' + value.numlin
        // urltipo = 'api/v1/arts.bylinea/' + value.numlin

        const pagina = value.page.toString()
        urltipo = 'api/v1/arts.bylinea.paginados/' + value.numlin + '?' + 'OFFSET=' + pagina + '&LIMIT=20'

        // CATEGORIA DIRECTA
      } else {
        //  // let urltipoclaseCategoria  = 'api/v1/arts.byCatego/' + value.categoria
        // urltipo = 'api/v1/arts.bycategoria/' + value.categoria

        const pagina = value.page.toString()
        urltipo = 'api/v1/arts.bycategoria.paginados/' + value.categoria + '?' + 'OFFSET=' + pagina + '&LIMIT=20'
      }

      if (value.tipoclase == 'linea.insignia') {
        const pagina = value.page.toString()
        urltipo = 'api/v1/arts.bylinea.pag.insignia/' + value.numlin + '?' + 'OFFSET=' + pagina + '&LIMIT=20'
      }

      if (value.tipoclase == 'categoria.insignia') {
        const pagina = value.page.toString()
        urltipo = 'api/v1/arts.bycategoria.insignia/' + value.categoria + '?' + 'OFFSET=' + pagina + '&LIMIT=20'
      }

      // console.log("urltipo", urltipo)
      return new Promise((resolve, reject) => {
        // 'api/v1/arts.bylinea/' + value.numlin
        var articulos = []
        Vue.http.get(urltipo).then(response => {
        // console.log("repsonse artline articulos", response)
          // console.log("dataxlinea:",response.data)
          // SI ESTA VACIO NO HACER NADA
          if (response.data.Arts == null) {
            articulos = []
            resolve(articulos)
            commit('ARTICULOSXLINEA', articulos)
            resolve()
          } else {
            // console.log ("value.precioutil", value.precioutil)
            // console.log("repsonse.data Artlinea", response.data)

            const nTotal = response.data.Total

            response.data.Arts.forEach((element) => {
              var valorInicial = element.Precio1

              switch (value.precioutil) {
              case '1':
                element.Precio1 = element.Precio1
                break

              case '2':
                element.Precio1 = element.Precio2
                break

              case '3':
                element.Precio1 = element.Precio3
                break

              case '4':
                element.Precio1 = element.Precio4
                break

              case '5':
                element.Precio1 = element.Precio5
                break

              default:
                element.Precio1 = element.Precio1
                break
              }

              // SI No hay precio,,, o cero. debe pober 1....)
              if (element.Precio1 == '') {
                element.Precio1 = valorInicial
              }

              var impuesto = 1
              if (element.Impuesto1 > 0) {
                impuesto = (1 + (element.Impuesto1 / 100))
              }

              // ACTUALIZAR PRECIOS POR DIVISA
              if (element.Divisa == 'D' && value.divisa == 'P') {
                element.Divisa = 'P'
                element.Preciopub = parseFloat(element.Precio1) * impuesto * value.tc
                element.Precio1 = parseFloat(element.Precio1) * value.tc
              } else if (element.Divisa == 'P' && value.divisa == 'D') {
                element.Divisa = 'D'
                element.Preciopub = (parseFloat(element.Precio1) * impuesto) / value.tc
                element.Precio1 = parseFloat(element.Precio1) / value.tc
              } else {
                element.Preciopub = (parseFloat(element.Precio1) * impuesto)
              }

              // IMAGEN
              if (element.Imagen_name != '') {
                element.Imagen_name = url + element.Imagen_name
              }

              // TIPOEMP
              if (element.Tipoemp != '') {
                element.Tipoemp = element.Tipoemp
              } else {
                element.Tipoemp = 'F'
                // console.log(element.Tipoemp, element.Numart)
              }

              // calculo de precio bajo
              const nPrecioPub = accounting.unformat(element.Preciopub)
              const nDescuento = element.Pjedesc
              const nPreciobajo = nPrecioPub * (1 - (nDescuento / 100))
              const preciobajo = accounting.formatNumber(nPreciobajo, 2)

              articulos.push({
                preciopub: accounting.formatNumber(element.Preciopub, 2),
                precio: accounting.formatNumber(element.Precio1, 2),
                preciobajo: preciobajo,
                img: element.Url,
                url: element.Url,
                Imagen_name: element.Imagen_name,
                text: element.Nomlin,
                details: element.Descrip,
                descrip: element.Descrip,
                estatus: element.Statusweb,
                existencia: parseInt(element.Existencia).toFixed(0),
                modelo: element.Modelo,
                marca: element.Marca,
                unidad: element.Unidad,
                clave: element.Numart,
                numart: element.Numart,
                divisa: element.Divisa,
                cantidad: 0,
                impuesto: element.Impuesto1,
                impuesto1: element.Impuesto1,
                pjedesc: element.Pjedesc,
                nTotal: nTotal,
                tipoemp: element.Tipoemp
              })
            })

            // console.log("articulos", articulos)
  					commit('ARTICULOSXLINEA', articulos)

  					resolve(articulos)
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },

    traerPromos ({ commit }, value) {
      return new Promise((resolve, reject) => {
        var url = Vue.http.options.root.replace('tienda3', 'fotossait/')

        var articulos = []
        Vue.http.get('api/v1/promo.activa.list').then(response => {
          // SI ESTA VACIO NO HACER NADA
          // console.log("activa.list", response)
          var respuesta = response.data.Partidas
          var resp = response.body.ntotal

          if (resp > 0) {
            respuesta.forEach((element) => {
              if (element.Divisa == 'D' && value.divisa == 'P') {
                element.Divisa = 'P'
                element.preciopub = parseFloat(element.preciopub) * value.tc
                element.precio1 = parseFloat(element.precio1promo) * value.tc
                element.precionormal = parseFloat(element.precionormal) * value.tc
                element.preciopubarts = parseFloat(element.preciopubarts) * value.tc
              } else if (element.Divisa == 'P' && value.divisa == 'D') {
                element.Divisa = 'D'
                element.precio1 = parseFloat(element.precio1promo) / value.tc
                element.preciopub = parseFloat(element.preciopub) / value.tc
                element.precionormal = parseFloat(element.precionormal) / value.tc
                element.preciopubarts = parseFloat(element.preciopubarts) / value.tc
              }

              // if(element.Imagen_name != ''){
              //   element.Imagen_name = url + element.Imagen_name
              // }

              articulos.push({
                preciopub: accounting.formatNumber(element.preciopub, 2),
                precio: accounting.formatNumber(element.preciopubarts, 2),
                img: element.url,
                text: element.Nomlin,
                details: element.Descrip,
                estatus: element.Statusweb,
                existencia: parseInt(element.Existencia).toFixed(0),
                modelo: element.Modelo,
                marca: element.Marca,
                unidad: element.Unidad,
                clave: element.numart,
                numart: element.numart,
                divisa: element.Divisa,
                cantidad: 0,
                impuesto: element.Impuesto1,
                impuesto1: element.Impuesto1,
                pjedesc: element.Pjedesc,
                limite: element.Limite,
                tipoemp: element.tipoemp
              
              })
            })
          }
          // console.log (articulos)
          commit('PROMOS', articulos)
          resolve(articulos)
        }).catch(error => {
          // var articulos = null
          commit('PROMOS', articulos)
          console.log(error)
        })
      })
    },

    bucarArt ({ commit }, value) {
      var url = Vue.http.options.root.replace('tienda3', 'fotossait/')

      commit('ARTICULOS', [])
      var accounting = require('accounting')
      return new Promise((resolve, reject) => {
        var payload = { BuscAart: value.BuscAart }
        var articulos = []

        // console.log('value', value)
        Vue.http.post('api/v2/product.find.list', payload).then(response => {
          // var respuesta = response.body.Productos
          console.log(response)
          
          

          var respuesta = response.data.Data
          // console.log(respuesta)
          // SI ESTA VACIO NO HACER NADA
          if (respuesta == null) {
            articulos = []
            resolve(articulos)
            commit('ARTICULOS', articulos)
            return
          }

          respuesta.forEach((element) => {
            // Solo productos activos

            if (element.Statusweb == 0) {
              return
            }
            var valorInicial = element.Precio1
            switch (value.precioutil) {
            case '1':
              element.Precio1 = element.Precio1
              break

            case '2':
              element.Precio1 = element.Precio2

              break

            case '3':
              element.Precio1 = element.Precio3
              break

            case '4':
              element.Precio1 = element.Precio4
              break

            case '5':
              element.Precio1 = element.Precio5
              break

            default:
              element.Precio1 = element.Precio1
              break
            }

            if (element.Precio1 == '') {
              element.Precio1 = valorInicial
            }

            var impuesto = 1
            if (element.Impuesto1 > 0) {
              impuesto = (1 + (element.Impuesto1 / 100))
            }

            if (element.Divisa == 'D' && value.divisa == 'P') {
              element.Divisa = 'P'
              element.Preciopub = parseFloat(element.Precio1) * impuesto * value.tc
              element.Precio1 = parseFloat(element.Precio1) * value.tc
            } else if (element.Divisa == 'P' && value.divisa == 'D') {
              element.Divisa = 'D'
              element.Preciopub = (parseFloat(element.Precio1) * impuesto) / value.tc
              element.Precio1 = parseFloat(element.Precio1) / value.tc
            } else {
              element.Preciopub = (parseFloat(element.Precio1) * impuesto)
            }

            var descuento = 0
            if (element.Pjedesc > 0) {
              descuento = element.Preciopub * (element.Pjedesc / 100)
            }

            if (element.Imagen_name != '') {
              element.Imagen_name = url + element.Imagen_name
            }



            articulos.push({
              preciopub: accounting.formatNumber(element.Preciopub, 2),
              precio: accounting.formatNumber(element.Precio1, 2),
              img: element.Url,
              details: element.Descrip,
              estatus: element.Statusweb,
              existencia: parseInt(element.Existencia).toFixed(0),
              modelo: element.Modelo,
              marca: element.Marca,
              unidad: element.Unidad,
              clave: element.Numart,
              numart: element.Numart,
              divisa: element.Divisa,
              cantidad: 0,
              impuesto: element.Impuesto1,
              impuesto1: element.Impuesto1,
              pjedesc: element.Pjedesc,
              importe: accounting.formatNumber(element.Preciopub, 2),
              descuento: descuento,
              descripCompleta: element.Obs
            })
          })
          commit('ARTICULOS', articulos)
          resolve(articulos)
        }).catch(error => {
          console.log(error)
        })
      })
    },

    verArt ({ commit, dispatch }, value) {
      // console.log("vertat value en articulos.js",value)

      let url = Vue.http.options.root.replace('tienda3', 'fotossait/')
      let url2 = Vue.http.options.root.replace('tienda3', 'pdfsait/')

      return new Promise((resolve, reject) => {
        // let numart = value.numart.padStart(20)

        const payload = { numart: value.numart }
        Vue.http.post('api/v1/articulos.info', payload).then(response => {
          // console.log("articulos.info",response.data)
          let articulos = {}
          let art = response.data

          let valorInicial = art.Precio1
          switch (value.precioutil) {
          case '1':
            art.Precio1 = art.Precio1
            break

          case '2':
            art.Precio1 = art.Precio2
            break

          case '3':
            art.Precio1 = art.Precio3
            break

          case '4':
            art.Precio1 = art.Precio4
            break

          case '5':
            art.Precio1 = art.Precio5
            break

          default:
            art.Precio1 = art.Precio1
            break
          }

          if (art.Precio1 == '') {
            art.Precio1 = valorInicial
          }

          var impuesto = 1
          if (art.Impuesto1 > 0) {
            impuesto = (1 + (art.Impuesto1 / 100))
          }
          // console.log(impuesto, art.Divisa)
          if (art.Divisa == 'D' && value.divisa == 'P') {
            art.Divisaart = art.Divisa
            art.Divisa = 'P'
            art.preciopub = parseFloat(art.Precio1) * impuesto * value.tc
            art.Precio1 = parseFloat(art.Precio1) * value.tc
          } else if (art.Divisa == 'P' && value.divisa == 'D') {
            art.Divisaart = art.Divisa
            art.Divisa = 'D'
            art.preciopub = (parseFloat(art.Precio1) * impuesto) / value.tc
            art.Precio1 = parseFloat(art.Precio1) / value.tc
          } else {
            art.Divisaart = art.Divisa
            art.preciopub = (parseFloat(art.Precio1) * impuesto)
            art.Precio1 = art.Precio1
          }

          // console.log(art.Precio1)
          
          var descuento = 0
          if (art.Pjedesc > 0) {
            descuento = accounting.unformat(art.preciopub) * (art.Pjedesc / 100)
          }

          // IMAGEN
          if (art.Imagen_name != '') {
            art.Imagen_name = url + art.Imagen_name
          }

          // console.log(this.getArt)
          // let urllinkPdf = this.$http.options.root.replace("tienda3","pdfsait/")

          const pdf2link = url2 + art.Pdf2
          const pdfslink = url2 + art.Pdfs
          // console.log("pdflink ", pdfslink)

          // console.log("verArt precio1",  parseFloat(art.Precio1.toFixed(2)))
          // console.log(art)
          articulos = {
            url: art.Url,
            imagen_name: art.Imagen_name,

            preciopub: accounting.formatNumber(art.preciopub, 2),
            precio: accounting.formatNumber(art.Precio1, 2),
            precio1: art.Precio1.toFixed(2),
         
            altura: art.Altura,
            ancho: art.Ancho,
            largo: art.Largo,
            peso: art.Peso,

            linea: art.Linea,
            familia: art.Familia,
            categoria: art.categoria,
            valdep: art.Valdep,

            id: art.Id,
            numart: art.Numart.trim(),
            clave: art.Numart.trim(),
            details: art.Descrip,
            divisa: art.Divisa,
            divisaart: art.Divisaart,

            impuesto: art.Impuesto1,
            impuesto1: art.Impuesto1,
            cantidad: 1.00,
            pjedesc: art.Pjedesc,

            existencia: art.existencia,

            youtube_link: art.Video,
            html_descrip: art.Html,

            marca: art.Marca,
            modelo: art.Modelo,
            descripCompleta: art.Html,
            html: art.html,
            obs: art.Obs,

            foto: art.Foto,
            fotos: art.Fotos,

            // descripCompleta: art.Obs.replace(" ", "<br/>"),
            
            servicio: art.Servicio,
            statusweb: art.Statusweb,

            tipoi: art.Tipoi,
            unidad: art.Unidad,
            img: art.Url,

            fotosarr: art.FotosArr,
            pdfs: art.Pdfs,
            pdf2: art.Pdf2,
            video: art.Video,
            tipoemp: art.tipoemp,
            pdfslink,
            pdf2link

          }
          // console.log("verart articulos", articulos)
          commit('VERART', articulos)
          resolve(art)
        }).catch(error => {
          console.log(error)
        })
      })
    }

  },

  getters: {
    getArticulos (state) {
      return state.articulos
    },

    getArticulosxLinea (state) {
      return state.articulosxlinea
    },

    getPromos (state) {
      return state.promos
    },

    getArt (state) {
      return state.art
    }
  }
}
