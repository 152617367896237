<template>
  <v-container class="ma-1 pa-1">
    <v-row class="ma-0 pa-0">
      <v-col
        class="ma-1 pa-1"
        cols="12"
        md="6"
        lm="6"
        sm="6"
        xm="12"
      >
        <v-text-field
          v-model="buscando"
          dense
          append-icon="mdi-microphone"
          class="mx-4"
          label="Buscar Cliente"
          prepend-inner-icon="mdi-magnify"
          clearable
          :loading="loading"
          @click:append="buscarcliente"
          @keyup.enter="buscarcliente"
          @click:clear="limpiar"
          @keyup.113="buscarcliente"
        />
        <p class="mx-5 my-0">
          {{ numcli }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="ma-0 pa-0"
        cols="12"
        md="12"
        lm="12"
        sm="12"
        xm="12"
      >
        <p
          v-if="clientes.length > 0"
          class="mx-5"
        >
          {{ clientes.length }} resultados
        </p>

        <v-data-table
          v-if="clientes.length > 0"
          ref="tabla"
          :headers="headers"
          :items="clientes"
          item-key="numcli"
          hide-default-footer
          class="elevation-1"
          :items-per-page="longitud"
          fixed-header
          dense
          :loading="loading"
          hide-default-header
          @click:row="seleccionar"
        >
          <template #item="{ item }">
            <tr @click.once="seleccionar(item)">
              <td>{{ item.nomcli }}</td>
              <td>{{ item.numcli }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    buscando: "",
    longitud: 30,
    loading: false,
    clientes: [],

    nomcli: "",
    numcli: "",

    headers: [
      {
        text: "Id",
        align: "start",
        value: "numcli",
      },
      { value: "nomcli", sort: false, align: "start" },
      // { text: 'Nombre Comercial'   , value: 'nomcomer' },
      // { text: 'Calle'              , value: 'Calle' },
      // { text: 'Ciudad'             , value: 'Ciudad' },
      // { text: 'Telefono'           , value: 'telefono' },
    ],
  }),

  computed: {
    ...mapGetters("carrito", ["getCarrito", "getTC"]),
    ...mapGetters("Login", ["getModo", "getCliente"]),
  },


  created() {
    if (this.getCliente != "") {
      this.precioutil = this.getCliente.precioutil;
      // Validar si es cleinte del vendedor
    }
    // console.log ("this.getCliente", this.getCliente.nomcli)
    this.buscando = this.getCliente.numcli;
    this.numcli = this.getCliente.nomcli;
  },

  methods: {
    ...mapActions("carrito", ["carritoadd", "traerTC", "limpiarCarrito"]),
    ...mapActions("Login", ["updateCliente"]),

    buscarcliente() {
      // ELIMINANDO BUSQUEDAS VACIAS
      if (this.buscando == null) return;
      // console.log("a Buscar", this.buscando)
      var payload = {
        Buscacli: this.buscando.trim(),
      };

      // console.log("payload",payload)
      // tienda3/api/v2/clientes.find
      this.$http
        .post("api/v3/clientes.find", payload)
        .then((response) => {
          // console.log(response.data)
          this.loading = false;

          if (response.data.Total > 0) {
            this.clientes = response.data.Data;
            this.longitud = response.data.Total;

            // pasar el focus
            // this.setFocus()
          } else {
            this.clientes = [];
          }
        })
        .catch((error) => {
          this.clientes = [];
          this.loading = false;
          console.log();
        });
    },

    limpiar() {
      this.clientes = [];
      this.clientes = "";
      this.numcli = "";
      this.nomcli = "";

      var item = {};
      this.updateCliente(item);
    },

    seleccionar(item, row) {
      console.log("row", row); // row.select(true);
      console.log("item", item);
      this.nomcli = item.nomcli;
      this.numcli = item.nomcli;

      this.buscando = item.numcli;
      this.clientes = [];
      // this.buscando = ''
      this.updateCliente(item);
    },
  },
};
</script>
