<template>
  <v-carousel
    class="ma-1"
    cycle
    hide-delimiter-background
    show-arrows-on-hover
    width="100%"
    height="100%"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      contain
      max-height="400"
      :src="slide.img"
      color="primary"
    >
      <!-- <v-sheet
        color="white"
      >
        <img :src="slide.img" width="100%" height="100%">

      </v-sheet> -->
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data() {
    return {
      slides: [],
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.slides = [];
      this.$http.get("api/v1/banners.list").then((response) => {
        var img = response.body;
        // validamos que la respuesta no este vacia
        if (img.ntotal > 0) {
          // Reemplazamos tienda3 por fotossait para poder cargar las imagenes del servidor

          // VUE_APP_OPTIONS_ROOT=https://saitmd3.com/tienda3
          const cUrlWeb = process.env.VUE_APP_OPTIONS_ROOT;

          const url = cUrlWeb.replace("tienda3", "fotossait/");
          // Hacemos un ciclo para cargar las imagenes

          img.Banners.forEach((element) => {
            // Creamos un objeto donde ponemos el url
            var imagenes = { img: url + element.Nombanner };
            // Agregamos el elemento al arreglo
            this.slides.push(imagenes);
          });
        }
      });
    },
  },
};
</script>
