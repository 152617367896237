<template>
  <v-container fluid>
    <v-row
      align-space-around
      justify-center
      row
      fill-height
    >
      <a name="arriba" />

      <!-- Flex para las DEPARTAMENTO -->
      <v-col
        cols="12"
        xs="12"
      >
        <v-card class="transparent elevation-0">
          <v-card-title
            primary-title
            class="py-0"
          >
            <v-breadcrumbs
              :items="items"
              divider="/"
              class="py-0"
              @click="goTo(item.to)"
            >
              <template #divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <!-- Lineas: {{ categoria }}  -->
            <v-spacer />
            <v-btn
              color="primary"
              @click="regresar()"
            >
              <v-icon>reply</v-icon>
            </v-btn>
          </v-card-title>

          <v-row
            row
            wrap
            justify-space-around
          >
            <v-col
              v-for="(cat, i) in lineas"
              :key="i"
              cols="12"
              xs="6"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              class="pa-5"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mx-auto"
                  color="grey lighten-4"
                  @click="ver(cat)"
                >
                  <v-img
                    contain
                    :aspect-ratio="16 / 9"
                    :src="cat.img"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          grey
                          darken-1
                          v-card--reveal
                          white--text
                        "
                        style="height: 100%"
                      />
                    </v-expand-transition>
                  </v-img>
                  <v-footer
                    class="blue lighten-1 white--text"
                    align="center"
                  >
                    {{ cat.text }}
                  </v-footer>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-btn
        fab
        color="primary"
        class="white--text mb-10"
        fixed
        bottom
        right
        @click="arriba"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      hiscatego: "",
      linea: "",
      lineas: [],
      categoria: "",
      categorias: [],
      menu: false,
      items: [],
      overlay: false,
    };
  },

  computed: {
    ...mapGetters("tema", ["getLogourl"]),
  },

  watch: {
    $route() {
      this.traerLineas();
    },
  },
  created() {
    var numcat = this.$route.params.id;
    console.log("cat ", numcat);
    this.getHistCatego(numcat);
    this.traerLineas();
  },

  methods: {
    getHistCatego(numcat) {
      this.$http
        .get("api/v1/hiscatego/" + numcat)
        .then((response) => {
          this.hiscatego = response.body;
          // console.log('categoriay depto: ',this.hiscatego)

          this.items.push({
            text: this.hiscatego.Nomdep,
            disabled: false,
            to: "/categoria/" + this.hiscatego.Valdep,
          });

          this.items.push({
            text: this.hiscatego.Nomcat,
            disabled: false,
            to: "/lineas/" + this.hiscatego.Numcat,
          });
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },

    goTo(payload) {
      this.$router.to(payload);
    },

    // Traer el articulo
    ver(numlin) {
      // Mandar a llamar los articulos con el numero de linea
      // var numLinea = numlin.trim()
      this.$router.push({
        name: "artlinea",
        params: { linea: numlin.numlin.trim() },
      });
    },

    regresar() {
      this.$router.push({
        name: "categoria",
        params: {
          id: this.hiscatego.Valdep.trim(),
        },
      });
    },

    traerLineas() {
      var valLinea = this.$route.params.id;

      this.$http
        .get("api/v1/lineas.bycategory/" + valLinea)
        .then((response) => {
          var url = this.$http.options.root.replace("tienda3", "fotossait/");
          console.log(response);

          if (typeof response.body === "object") {
            this.lineas = [];
            // Cargar solo Categorias Activos
            response.body.forEach((element) => {
              if (element.Imagen_name != "") {
                element.Imagen_name = url + element.Imagen_name;
              }

              if (element.Url == "") {
                element.Url = this.getLogourl;
              }

              this.lineas.push({
                img: element.Url,
                text: element.Nomlin,
                numlin: element.Numlin,
                url: element.Url,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    arriba() {
      window.scrollTo(500, 0);
    },
  },
};
</script>
