import Vue from 'vue'
import Vuex from 'vuex'

export default {

  namespaced: true,
  state: {
    tipoemp: []
  },

  mutations: {
    TIPOEMP (state, tabla) {
      state.tipoemp = tabla
    }
  },

  actions: {
    setTipoemp ({ commit }, tabla) {
      // console.log("tabla envios", tabla)
      commit('TIPOEMP', tabla)
    }

    // recalcularEnvio({commit}, productos){
    // 	return new Promise((resolve, reject) => {
    //       	commit('DIRECCION',productos)
    //       	resolve(true)
    // 	})
    // },
  },

  getters: {
    getTipoemp (state) {
		  return state.tipoemp
    }

  }
}
