<template>
  <v-container v-if="mostrarColores">
    <v-row class="fill-height" align="center" justify="center">
      <template>
        <v-col
          v-for="producto in productos"
          :key="producto.numart"
          cols="12"
          md="2"
          xm="2"
          sm="12"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              max-height="300"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="transparent"
              @click="info(producto.Numart)"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-img
                    max-height="300"
                    contain
                    v-bind="attrs"
                    :src="producto.Url"
                    v-on="on"
                  >
                    <v-card-title class="text-h6 white--text">
                      <v-row class="fill-height flex-column" justify="space-between" />
                    </v-card-title>
                  </v-img>
                </template>
                <span>{{ producto.Descrip }}</span>
              </v-tooltip>

              <!-- <v-card-actions>
                <v-btn color="secondary" dark small @click="agregar">
                  Agregar
                  <v-icon right> shopping_cart </v-icon>
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Coloresgrid",
  data() {
    return {
      cantidad: 1,
      myCant: 1,
      transparent: "rgba(255, 255, 255, 0)",
      // icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      loading: false,
      slider: null,
      productos: [],
      mostrarColores: false,
      numartActual: "",
      show: false,
    };
  },

  computed: {
    ...mapGetters("articulos", ["getArt"]),
    ...mapGetters("carrito", ["getTC", "getCarrito"]),
  },

  created() {
    this.numartActual = this.$route.params.numart.trim();
    this.init();
  },

  methods: {
    ...mapActions("carrito", [, "carritoadd"]),
    info(numart) {
      // console.log(numart);
      var NumArt = numart.trim();
      this.$router
        .push({ name: "verartinsignia", params: { numart: NumArt } })
        .catch((err) => {
          console.log(err);
        });
    },

    init() {
      this.mostrarColores = false;
      // Traer la Familia del articulo
      const payload = { numart: this.numartActual };
      this.$http
        .post("api/v1/articulos.info", payload)
        .then((response) => {
          // this.$http.get('api/v1/product.info/' + this.numartActual).then(response=>{
          // console.log("coloresgrid init", response);
          this.familia = response.data.Familia;
          if (response.data.Familia.trim() == "") {
            return;
          }

          // Traer los productos de la familia (color)
          this.$http
            .get("api/v1/arts.by.familia/" + this.familia.trim())
            .then((response) => {
              // console.log("mostrarColores", response);

              if (response.data.length > 0) {
                this.productos = response.data;
                this.mostrarColores = true;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
