<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <Carrousel />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Promociones />
      </v-col>
    </v-row>

    <Novedades class="mt-3" />

    <v-row>
      <v-col cols="12">
        <Destacados class="mt-3" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Ubicacion class="mt-3" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Carrousel from "@/views/home/Carrousel.vue";
// import Carrousel2 from '@/views/home/Carrousel2.vue'
import Novedades from "@/views/home/Novedades.vue";
import Destacados from "@/views/home/Destacados.vue";
import Ubicacion from "@/views/home/Ubicacion.vue";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import Promociones from "@/components/Promociones.vue";
var accounting = require("accounting");

export default {
  components: {
    Promociones,
    // Carrousel2,
    Carrousel,
    Novedades,
    Destacados,
    Ubicacion,
  },
  data: () => ({
    items: [],
    cliente: "",
    clintesArr: [],
    search: "",
    clientesAll: [],
  }),
  
  computed: {
    ...mapGetters("carrito", ["getCarrito", "getTC"]),
    ...mapGetters("articulos", ["getArticulos"]),
    ...mapGetters("Login", ["getModo"]),
  },
  created() {
    this.traerTC().then((response) => {
      var payload = { divisa: this.$store.state.tipomoneda, tc: this.getTC };

      // if(this.getArticulos == ''){
      //   this.traerAllArticulos(payload).then(response =>{
      //   })
      // }

      if (this.$store.state.tipomoneda == "D") {
        this.moneda = true;
      } else {
        this.moneda = false;
      }
    });
  },

  methods: {
    ...mapActions("carrito", ["carritoadd", "traerTC", "limpiarCarrito"]),
    ...mapActions("articulos", ["traerAllArticulos"]),
    ...mapActions("Login", ["updateCliente"]),

    ver(NumArt) {
      this.$router.push({ name: "verart", params: { numart: NumArt } });
    },

    agregar(articulo) {
      articulo.cantidad = articulo.cantidad + 1;

      this.carritoadd(articulo).then((response) => {
        this.snackbar = true;
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          for (var j = this.items.length - 1; j >= 0; j--) {
            if (this.items[j].clave == this.getCarrito[i].clave) {
              this.items[j].cantidad = 1;
            }
          }
        }
        this.$store.state.carrito.carritovisible = true;
      });
    },

    // BUSCAR CLIENTE
    submit() {
      this.clintesArr = [];
      this.clientesAll = [];
      var payload = { Buscacli: this.cliente };
      this.$http.post("auth/api/v1/clientes.find", payload).then((response) => {
        this.clientesAll = response.body;
        for (var i = response.body.length - 1; i >= 0; i--) {
          this.clintesArr.push(response.body[i].nomcli);
        }
      });
    },

    evento(valor) {
      for (var i = this.clientesAll.length - 1; i >= 0; i--) {
        if (this.clientesAll[i].nomcli === valor) {
          this.$http
            .get("auth/api/v1/clientes/" + this.clientesAll[i].idweb)
            .then((response) => {
              this.updateCliente(response.body);
              var value = [];
              this.limpiarCarrito(value);
            });
        }
      }
    },

    arriba() {
      window.scrollTo(500, 0);
    },
  },


};
</script>
