import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import vuetify from '@/plugins/vuetify'

export default {
  namespaced: true,
  state: {
    rela2: []
  },

  mutations: {

    RELACIONADOS (state, rela2) {
      state.rela2
    }

  },

  actions: {

  },

  getters: {

    getRelacionados (state) {
      return state.rela2
    }

  }
}
