export default {
  namespaced: true,
  state: {
    direcciones: [],
    direnvio:{
      nombre: "",
      idweb: "",
      encargado: "",
      Calle: "",
      Numext: "",
      colonia: "",
      Ciudad: "",
      Estado: "",
      Cp: "",
      telefono: "",
      numclisuc: "",
    }
  },

  mutations: {
    DIRECCIONES (state, tabla) {
      state.direcciones = tabla
    },
    DIRENVIO (state, direnvio){
      state.direnvio = direnvio
    }
  },

  actions: {
    setDirecciones ({ commit }, tabla) {
      // console.log("tabla envios", tabla)
      commit('DIRECCIONES', tabla)
    },
    setDirenvio ({commit}, direnvio){
      commit ('DIRENVIO', direnvio)
    }

  },

  getters: {
    getDirecciones (state) {
		  return state.direcciones
    },
    getDirenvio (state){
      return state.direnvio
    }

  }
}
