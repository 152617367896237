<template>
  <v-container>
    <v-row row fill-height>
      <v-snackbar v-model="snackbar" :timeout="3000" top color="info" dark>
        {{ msg }}
        <v-btn color="white" text @click="snackbar = false"> Close </v-btn>
      </v-snackbar>

      <v-progress-linear
        v-if="loading"
        class="ma-0 pa-0"
        :indeterminate="loading"
        color="info"
        reverse
        stream
      />

      <!-- CATEGORIA//LINEA  -->
      <v-col cols="12" xs="12">
        <v-card-title primary-title class="ma-0 pa-0">
          <v-breadcrumbs :items="values" divider="/" class="pa-0" @click="goTo(item.to)">
            <template #divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>

          <!-- getCliente {{ getCliente}} Precio Util {{ precioutil}} -->
          <v-spacer />
          <!-- <v-btn text small class="mx-2" color="success">{{paginacion}}</v-btn>   -->

          <p class="text--body-2" />
          <v-btn text small class="mx-2" color="success"> Precios incluyen iva </v-btn>

          <!-- <v-btn small class="mx-2" color="info" @click="regresar()"><v-icon>reply</v-icon></v-btn> -->
        </v-card-title>
      </v-col>

      <!-- //CARGAR EL CLIENTE EN MODO VENDEDOR -->
      <v-col v-if="getModo === 'vendedor'" cols="12" xs="12">
        <v-card-text>
          <v-autocomplete
            v-model="cliente"
            label="Selecciona un cliente"
            :items="clintesArr"
            clearable
            append-icon="search"
            @keyup.enter="submit"
            @input="evento(cliente)"
          />
        </v-card-text>
      </v-col>

      <!-- LINEA DE BUSQUEDA   -->
      <v-col v-if="overlay" cols="12" xs="12">
        <v-progress-linear indeterminate color="red darken-2" />
      </v-col>

      <!-- <v-col cols="6" xl="2" lg="2" md="2" sm="4" xs="4" -->
      <!-- MOSTRAR ARTICULOS POR LINEA -->
      <v-col
        v-for="(artsxli, i) in getArticulosxLinea"
        :key="i"
        cols="12"
        xl="3"
        lg="3"
        md="4"
        sm="4"
        xs="12"
        class="pa-1"
      >
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            class="pa-0 ma-0"
            outlined
            elevation="10"
            :class="`elevation-${hover ? 12 : 2}`"
          >
            <v-img
              v-if="artsxli.img == ''"
              class="pa-1 ma-1"
              :src="logourl"
              alt="Sin imagen"
              height="180"
              contain
              @click="info(artsxli.numart)"
            />

            <v-img
              v-else
              class="pa-1 ma-1"
              :src="artsxli.img"
              alt="Foto"
              contain
              height="180"
              @click="info(artsxli.numart)"
            />

            <!-- <colorescard :title= artsxli.clave></colorescard> -->

            <!-- CLAVE Y DESCRIPCION -->
            <v-card-text
              class="py-0 ma-0"
              style="height: 80px"
              @click="info(artsxli.numart)"
            >
              <v-list-item-content class="text--darken-2">
                <div class="text-xs-left red--text">
                  <h4>{{ artsxli.clave }}</h4>
                </div>
                <!-- <h5>{{ artsxli.details }}</h5> -->
                <h5 v-if="artsxli.details.length > 80">
                  {{ artsxli.details.substr(0, 80) }}
                </h5>
                <h5 v-else>
                  {{ artsxli.details }}
                </h5>
              </v-list-item-content>

              <h3 v-if="artsxli.pjedesc > 0.0" class="primary--text">
                <del>$ {{ artsxli.preciopub }}</del>
              </h3>
              <h3 v-else class="primary--text text-center">$ {{ artsxli.preciopub }}</h3>
              <v-spacer />
              <h3 v-if="artsxli.pjedesc > 0.0" class="red--text text-center">
                $ {{ artsxli.preciobajo }}
              </h3>
            </v-card-text>

            <!-- Comprar -->
            <v-card-actions class="mt-2">
              <v-spacer />

              <v-btn
                v-if="artsxli.existencia > 0.0"
                color="primary"
                fab
                x-small
                @click="validar(artsxli)"
              >
                <v-icon> add_shopping_cart </v-icon>
              </v-btn>

              <v-btn small color="red" dark v-else>Agotado</v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <!-- PAGINACION -->
    <v-row justify="center">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="page"
            class="my-4"
            :length="paginacion"
            @input="masproductos"
          />
        </v-container>
      </v-col>
    </v-row>

    <v-btn
      fab
      color="primary"
      class="white--text mb-12"
      fixed
      bottom
      right
      @click="arriba"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import colorescard from '@/views/articulos/colorescard'

var accounting = require("accounting");
// import DescripArt from '@/views/articulos/DescripArt.vue'

export default {
  // components: {
  //   colorescard
  // },
  data: () => ({
    dialogm1: "",
    dialog: false,
    page: 1,
    paginacion: 1,
    loading: false,
    hislinea: "",
    precioutil: "",

    artxlinea: [],
    snackbar: false,
    msg: "",
    show: false,
    readonly: false,
    items: [],
    cliente: "",
    clintesArr: [],
    search: "",
    clientesAll: [],
    overlay: false,
    colors: ["primary", "secondary", "yellow darken-2", "red"],
    logourl: "",
    values: [],
    tipoclase: "linea",
  }),

  computed: {
    ...mapGetters("carrito", ["getCarrito", "getTC", "getPaquetes"]),
    ...mapGetters("articulos", ["getArticulosxLinea"]),
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getInfoVend",
      "getModo",
      "getClientes",
      "getCliente",
      "getLogeado",
    ]),
    ...mapGetters("tema", ["getLogourl"]),
    ...mapGetters("config", ["getConfig"]),
    ...mapGetters("calculoEnvios", ["getEnvios"]),
    ...mapGetters("tipoemp", ["getTipoemp"]),
  },

  watch: {
    $route() {
      // console.log("artlinea router wathc",this.$route)
      this.paginacion = 1;

      this.traerTC().then((response) => {
        const numlin = this.$route.params.linea;
        this.numlin = numlin;

        const categoria = this.$route.params.categoria;

        if (categoria !== undefined) {
          // console.log("CATEGORIA ");
          this.tipoclase = "categoria";
        }

        if (numlin !== undefined) {
          // console.log("LINEAS")
          this.tipoclase = "linea";
          this.getHistLinea(numlin);
        }

        this.loading = true;
        this.values = [];

        // let numlin = this.$route.params.linea
        var payloadTipo = {
          divisa: this.$store.state.tipomoneda,
          numlin: numlin,
          categoria: categoria,
          tc: this.getTC,
          precioutil: this.getConfig.precioutil,
          tipoclase: this.tipoclase,
          page: (this.page - 1) * 20,
        };

        // console.log("payloadTipo ROUTER", payloadTipo)
        this.traerArticulosxLinea(payloadTipo).then((response) => {
          // console.log("traerArticulosxLinea", response);
          this.loading = false;
          this.overlay = false;
          // this.page = getArticulosxLinea.Total/20
          // console.log("response", response)

          // console.log("RESPONSE", response.length )
          // console.log("paginas", response[0].nTotal/20)
          if (response.length > 0) {
            this.paginacion = parseInt(response[0].nTotal / 20);
          }
        });
      });
    },
  },

  created() {
    this.precioutil = this.getConfig.precioutil;
    this.init();
  },

  methods: {
    ...mapActions("carrito", ["carritoadd", "traerTC", "setultnumart"]),
    ...mapActions("articulos", ["traerArticulosxLinea", "SetArticulosxLineaPaginados"]),

    init() {
      this.loading = true;
      this.logourl = this.getLogourl;
      this.precioutil = this.getConfig.precioutil;

      // GET CLIENTE
      if (this.getCliente != "") {
        this.precioutil = this.getCliente.precioutil;
        // Validar si es cleinte del vendedor
      } else {
        // NO HAY CLIENTE: PRECIO !.
        this.precioutil = this.getConfig.precioutil;
      }

      // if(this.getClientes != ''){
      //   this.precioutil = this.getClientes.precioutil
      // }

      if (this.getCliente.nomcli != undefined) {
        this.clintesArr.push(this.getCliente.nomcli);
        this.cliente = this.getCliente.nomcli;
      }

      // ANALISIS DE ROUTER A CARGAR
      // console.log("router ", this.$route.params)
      const numlin = this.$route.params.linea;
      const categoria = this.$route.params.categoria;

      // ACTUALIZAR BREDCRUm
      this.getHistLinea(numlin);

      if (categoria !== undefined) {
        // console.log("CATEGORIA ")
        this.tipoclase = "categoria";
      }
      // else {
      //   console.log("LINEAS")
      //   this.tipoclase = "linea"
      // }
      if (numlin !== undefined) {
        // console.log("LINEAS")
        this.tipoclase = "linea";
      }

      this.traerTC()
        .then((response) => {
          // let numlin = this.$route.params.linea
          const payloadTipo = {
            divisa: this.$store.state.tipomoneda,
            numlin: numlin,
            categoria: categoria,
            tc: this.getTC,
            precioutil: this.getConfig.precioutil,
            tipoclase: this.tipoclase,
            page: (this.page - 1) * 18,
          };

          // console.log("payloatipo", payloadTipo)
          this.traerArticulosxLinea(payloadTipo)
            .then((response) => {
              this.loading = false;
              // console.log("productos", response);

              if (response.length == 0) {
                this.msg = "No hay articulos para mostrar";
                this.snackbar = true;
              }

              if (response.length > 0) {
                this.paginacion = parseInt(response[0].nTotal / 20);
              }
              // console.log("page", response[0].nTotal/20)
              // this.paginacion = parseInt(response[0].nTotal /20 )
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getHistLinea(numlin) {
      this.values = [];
      this.$http
        .get("api/v1/hislinea/" + numlin)
        .then((response) => {
          // console.log("Historial por linea",response.data)
          this.hislinea = response.body;

          this.values.push({
            text: this.hislinea.Nomdep,
            disabled: false,
            to: "/categoria/" + this.hislinea.Valdep,
          });

          this.values.push({
            text: this.hislinea.Nomcat,
            disabled: false,
            to: "/lineas/" + this.hislinea.Numcat,
          });

          this.values.push({ text: this.hislinea.Nomlin });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goTo(payload) {
      // console.log("payload", payload);
      this.$router.to(payload);
    },

    regresar() {
      // console.log(this.hislinea);
      this.$router
        .push({ name: "lineas", params: { id: this.hislinea.Numcat } })
        .catch((err) => {
          console.log(err);
        });
    },

    validar(articulo) {
      if (this.getModo == "vendedor") {
        if (this.$store.state.Login.cliente == "") {
          this.msg = "Selecciona un cliente antes de agregar cualquier articulo";
          this.snackbar = true;
        } else {
          this.agregar(articulo);
        }
        // modo pUBLICO
      } else {
        this.agregar(articulo);
      }
    },

    agregar(articulo) {
      // console.log("cantidad articulo pone 2:", articulo);
      articulo.cantidad = articulo.cantidad + 1;

      this.carritoadd(articulo).then((response) => {
        this.$store.state.carrito.carritovisible = true;
      });

      // console.log("getCarrito carritoAdd", this.getCarrito);
      let enviopar = {
        cantidad: 1,
        clave: "ENVIO",
        descrip: "ENVIO A DOMICILIO",
        descuento: 0,
        details: "ENVIO A DOMICILIO",
        divisa: "P",
        estatus: "1",
        existencia: "0",
        img: "",
        importe: "1.00",
        impuesto: 0,
        impuesto1: 16,
        iva: 0.0,
        marca: "",
        modelo: "",
        numart: "               ENVIO",
        pjedesc: 0,
        precio: 1,
        preciobajo: "1.00",
        preciopub: "1.00",
        subtotal: 1.0,
        text: "",
        unidad: "SERVI",
        url: "",
        tipoemp: "F",
      };

      // Agregar envio.
      // CASE MIKAI tipoCalEnv = 1
      const mikai = false;
      if (mikai === true) {
        var nTotal = accounting.unformat(this.total);
        this.preciobase = 0.0;
        this.getEnvios.forEach((element) => {
          // console.log(element.Monto)
          if (nTotal >= element.Monto) {
            // console.log("get.envios", element.Preciobase)
            this.preciobase = element.Preciobase;
          }
        });

        enviopar.cantidad = 1;
        enviopar.precio = this.preciobase;
        // console.log("mikai");
        // console.log(this.enviopar);
        this.carritoadd(this.enviopar);
      }

      // CASE REDLEAF
      const redleaf = true;

      if (redleaf == true) {
        // console.log('articulo', articulo)
        this.setultnumart(articulo);

        // SI NO EXISTE TIPOEMP... Se crea como Tipo F. No requiere envio.
        if (typeof articulo.tipoemp === undefined) {
          articulo.tipoemp = "F";
        }

        // console.log("getTipoEmp", this.getTipoemp);
        // let CostoEnvio = 0.0;
        // console.log("articulo", articulo);

        this.getTipoemp.forEach((element) => {
          if (element.tipoemp == articulo.tipoemp) {
            // CostoEnvio = element.costoenv;
            // console.log("element.tipoemp", element.costoenv);
            enviopar.precio = element.costoenv.toString();
            enviopar.cantidad = 1;
            enviopar.tipoemp = element.tipoemp;
          }
        });
        this.carritoadd(enviopar);
      }
    },

    // ver art
    info(numart) {
      var NumArt = numart.trim();
      this.$router.push({ name: "verart", params: { numart: NumArt } }).catch((err) => {
        console.log(err);
      });
    },

    // carga los clientes
    submit() {
      this.clintesArr = [];
      this.clientesAll = [];
      var payload = { Buscacli: this.cliente };
      this.$http
        .post("auth/api/v1/clientes.find", payload)
        .then((response) => {
          this.clientesAll = response.body;

          response.forEach((element) => {
            this.clintesArr.push(element);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // que chingados es evento
    evento(valor) {
      for (var i = this.clientesAll.length - 1; i >= 0; i--) {
        if (this.clientesAll[i].nomcli === valor) {
          this.$http
            .get("auth/api/v1/clientes/" + this.clientesAll[i].idweb)
            .then((response) => {
              this.updateCliente(response.body);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    arriba() {
      window.scrollTo(500, 0);
    },

    // PAGINACION
    masproductos() {
      // console.log('Mas producto', this.page)
      this.init();
    },
  },
};
</script>
