import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    direcciones: [],
    direccion: []
  },

  mutations: {
    DIRECCIONES (state, value) {
      state.direcciones = value
    },

    VERDIRECCION (state, direccion) {
      state.direccion = direccion
    }

  },

  actions: {
    traerDirecciones ({ commit }, numcli) {
      return new Promise((resolve, reject) => {
        let direcciones = []
        const dircliente = []
        Vue.http.get('auth/api/v1/succlibynumcli/' + numcli).then(response => {
          // console.log("traerDirecciones js", response.data.length)

          if (response.data.length > 0) {
            // console.log(response.data)
            direcciones = response.data

            direcciones.forEach(element => {
              // console.log("element", element)
              dircliente.push(element)
            })

            // console.log ("dirclientes",dircliente)

            commit('DIRECCIONES', dircliente)
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(function (error) {
          if (error.status == 500) {
            resolve(false)
          }
        })
      })
    },

    verDireccion ({ commit }, idweb) {
      return new Promise((resolve, reject) => {
        Vue.http.get('auth/api/v1/succli/' + idweb).then(response => {
          commit('VERDIRECCION', response.body)
          resolve(true)
        }).catch(function (error) {
          console.log('error', error.body)
        })
      })
    },

    actualizarDireccion ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.put('auth/api/v1/succcli.update/' + payload.idweb, payload).then(response => {
          if (response.status == 500) {
            resolve(false)
          } else {
            resolve(true)
          }
        }).catch(function (error) {
          if (error.status == 500) {
            resolve(false)
          } else {
            router.push({ name: 'envios' })
          }
          // console.log(error)
          // console.log('error', error.body)
        })
      })
    },

    addDireccion ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('auth/api/v1/succcli.add', payload).then(response => {
          if (response.status == 500) {
            resolve(false)
          } else {
            resolve(true)
          }
        }).catch(function (error) {
          if (error.status == 500) {
            console.log(error.body)
            resolve(false)
          }
        })
      })
    },

    deleteDireccion ({ dispatch, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.delete('auth/api/v1/succli/' + payload).then(response => {
          if (response.status == 200) {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(function (error) {
          console.log(error.status)
          console.log('error', error.body)
        })
      })
    }

  },

  getters: {
    getDirecciones (state) {
      // console.log("getters getDirecciones")
      return state.direcciones
    },

    getDireccion (state) {
      return state.direccion
    }

  }
}
