<template>
  <v-data-table
    v-if="mostrarDestacados"
    :headers="headers"
    :items="articulos"
    class="elevation-4 ma-1 grey lighten-2"
    hide-default-header
    hide-default-footer
    calculate-widths
  >
    <template #item="{ item }">
      <td>
        <v-hover class="ma-2">
          <v-card
            slot-scope="{ hover }"
            class="ma-0 pa-0"
            width="200px"
            height="370px"
            :class="`elevation-${hover ? 24 : 6} mt-2`"
            @click="info(item.Numart.trim())"
          >
            <!-- ACTUAL -->
            <v-row
              class="ma-0 pa-1"
              align-center
              jutify-center
              wrap
            >
              <v-card-text class="ma-0 pa-0">
                <v-img
                  v-if="item.Url == ''"
                  class="mx-1"
                  :src="logourl"
                  contain
                />

                <v-img
                  v-else
                  contain
                  class="mx-auto"
                  :src="item.Url"
                />
              </v-card-text>

              <v-card-text class="py-1 ma-2">
                <div class="blue--text">
                  <h4>{{ item.Numart }}</h4>
                </div>
                <v-list-item class="pa-1">
                  <v-list-item-content class="pa-1">
                    <h4>{{ item.Descrip.substr(0, 150) }}</h4>
                  </v-list-item-content>
                </v-list-item>
                <div>
                  <h4
                    v-if="item.Pjedesc > 0.0"
                    class="primary--text"
                  >
                    <del>$ {{ item.Preciopub }}</del>
                    <v-spacer />
                  </h4>

                  <h4
                    v-else
                    class="primary--text text-center"
                  >
                    $ {{ item.Preciopub }}
                  </h4>

                  <v-spacer />

                  <h4
                    v-if="item.Pjedesc > 0.0"
                    class="red--text text-center"
                  >
                    $ {{ item.preciobajo }}
                  </h4>
                </div>
              </v-card-text>
            </v-row>
          </v-card>
        </v-hover>
      </td>
    </template>
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-spacer />
        <v-toolbar-title><h3>Destacados</h3></v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
var accounting = require("accounting");

export default {
  data: () => ({
    headers: [{ text: "Calories", value: "Numart" }],
    articulos: [],
    show: false,
    colors: ["primary", "secondary", "yellow darken-2", "red"],
    logourl: "",
    precioutil: 1,
    mostrarDestacados: false,
  }),

  computed: {
    ...mapGetters("tema", ["getLogourl"]),
    ...mapGetters("config", ["getPrecioutil"]),
    ...mapGetters("config", ["getConfig"]),
  },

  watch: {
    getPrecioutil() {
      this.precioutil = this.getPrecioutil;
    },
  },

  created() {
    this.init();
  },

  methods: {
    // accounting.formatNumber(element.Precio1, 2),

    init() {
      this.logourl = this.getLogourl;
      // console.log(this.getPrecioutil)
      this.precioutil = this.getPrecioutil;
      // this.precioutil= "4"

      // console.log("destacados.precioutil", this.precioutil)
      // var url = this.$http.options.root.replace("tienda3", "fotossait/");
      var payload = { tipo: 1 };

      this.$http
        .get("api/v1/destacados.list")
        .then((response) => {
          // console.log("respnse", response);
          if (response.data != null) {
            this.mostrarDestacados = true;

            // console.log("novedades.list",response.data)
            response.data.forEach((element) => {
              const valorInicial = element.Precio1;

              switch (this.precioutil) {
                case "1":
                  element.Precio1 = element.Precio1;
                  break;

                case "2":
                  element.Precio1 = element.Precio2;
                  break;

                case "3":
                  element.Precio1 = element.Precio3;
                  break;

                case "4":
                  element.Precio1 = element.Precio4;
                  break;

                case "5":
                  element.Precio1 = element.Precio5;
                  break;

                default:
                  element.Precio1 = element.Precio1;
                  break;
              }

              if (element.Precio1 == "") {
                element.Precio1 = valorInicial;
              }

              // console.log("precio1 final", element.Precio1)
              element.Preciopub =
                element.Precio1 + (element.Precio1 * element.Impuesto1) / 100;
              // element.Preciopub = accounting.formatNumber(element.Preciopub, 2)

              // calculo de precio bajo
              var nPrecioPub = accounting.unformat(element.Preciopub);
              var nDescuento = element.Pjedesc;
              var nPreciobajo = nPrecioPub * (1 - nDescuento / 100);

              element.preciobajo = accounting.formatNumber(nPreciobajo, 2);
              element.Pjedesc = accounting.formatNumber(element.Pjedesc, 2);
              this.articulos.push(element);
            });

            this.loading = false;
            // console.log("articulos",this.articulos)
            // this.articulos = response.body.Productos
          }
          // console.log(this.articulos)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    info(numart) {
      var NumArt = numart.trim();
      // this.$router.push({ name: "verart", params: { numart: NumArt } }).catch((err) => {
      //   console.log(err);
      // });

      if (this.getConfig.preciosnetos == "1") {
        // this.usarInsignias = true;
        this.$router.push({ name: "verartinsignia", params: { numart: NumArt.trim() } });
      } else {
        // Normal
        this.$router.push({ name: "verart", params: { numart: NumArt.trim() } });
      }
    },
  },
};
</script>
