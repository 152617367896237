import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
// import router from '@/router'
// import vuetify from '@/plugins/vuetify';

export default {

  namespaced: true,
  state: {
    envios: []
  },

  mutations: {
    ENVIOS (state, tabla) {
      state.envios = tabla
    }

  },

  actions: {
    setEnvios ({ commit }, tabla) {
      // console.log("tabla envios", tabla)
      commit('ENVIOS', tabla)
    },

    recalcularEnvio ({ commit }, productos) {
      return new Promise((resolve, reject) => {
		      	commit('DIRECCION', productos)
		      	resolve(true)
      })
    }

  },

  getters: {
    getEnvios (state) {
		  return state.envios
    }

  }
}
