
export default {
  namespaced: true,
  state: {
    cliente: {
      numcli: '',
      nomcli: '',
      precioutil: '1',
      rfc: '',
      calle: '',
      numint: '',
      numext:'',
      numint: '',
      colonia: '',
      ciudad: '',
      estado: '',
      pais:''
      
    }
  },

  mutations: {
    CLIENTE (state, cliente) {
      state.cliente = cliente
    },

  },

  actions: {
    setCliente ({ commit }, cliente) {
      commit('CLIENTE', cliente)
    },

  },

  getters: {
    getCliente (state) {
      return state.cliente
    }
  }

}
