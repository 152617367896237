import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    config: {
      precioutil: '1',
      preciosnetos: '0',
      usacarac: '0',
      usaexist: '0'
    }

  },

  mutations: {
    CONFIG (state, config) {
      state.config = config
    },

    PRECIO_UTIL (state, precioutil) {
      state.config.precioutil = precioutil
    }

  },

  actions: {
    setConfig ({ commit }, config) {
      commit('CONFIG', config)
    },

    setPrecioutil ({ commit }, precioutil) {
      commit('PRECIO_UTIL', precioutil)
    }

  },

  getters: {
    getConfig (state) {
      return state.config
    },
    getPrecioutil (state) {
      return state.config.precioutil
    }
  }

}
