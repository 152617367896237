import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
var accounting = require('accounting')
export default {
  namespaced: true,
  state: {
    documento: [],
    totales: ''
  },

  mutations: {
    DOCUMENTO (state, value) {
      state.documento = value
    },

    TOTALES (state, value) {
      state.totales = value
    }
  },

  actions: {
    addDocumento ({ commit }, documento) {
      commit('DOCUMENTO', documento)
    },

    addTotales ({ commit }, totales) {
      commit('TOTALES', totales)
    }
  },

  getters: {
    getDocumento (state) {
      return state.documento
    },

    getTotales (state) {
      return state.totales
    }
  }
}
